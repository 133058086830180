import { reactive } from 'vue';

import Analysis from '@/api/Analysis.js';
export default ({ params }) => {
  const data = reactive([]);
  const fetcher = () => {
    Analysis.subject({
      period: params.period,
    }).then((res) => {
      data.length = 0;
      if (res.code === 1) {
        data.push({ id: 'all', name: '全部' }, ...res.data.rows);
      }
    });
  };

  return {
    data,
    fetcher,
  };
};
