<template>
  <div
    class="flex space-x-4 items-center md:flex-col md:space-x-0 md:space-y-2"
  >
    <el-date-picker
      v-model="startModel"
      :disabled="disabled"
      type="date"
      value-format="YYYY-MM-DD"
      placeholder="選擇開始時間"
      class="flex-grow md:w-full"
      :disabled-date="disabledStartDate"
      :clearable="clearable"
      @change="checkTimeStart"
    >
    </el-date-picker>
    <span>
      <font-awesome-icon
        :icon="['fas', 'arrow-right']"
        class="text-lg text-gray md:rotate-90"
      />
    </span>
    <el-date-picker
      v-model="endModel"
      :disabled="disabled"
      type="date"
      value-format="YYYY-MM-DD"
      placeholder="選擇結束時間"
      class="flex-grow md:w-full"
      :clearable="clearable"
      :disabled-date="disabledEndDate"
    >
    </el-date-picker>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { toRefs } from 'vue';
import { useVModel } from '@vueuse/core';
import { ElDatePicker, ElMessage } from 'element-plus';
export default {
  components: {
    ElDatePicker,
  },
  props: {
    start: {
      type: String,
      default: '',
    },

    end: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    startDate: {
      type: Object,
      default: () => {},
    },
    endDate: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:start', 'update:end'],
  setup(props, { emit }) {
    const { startDate, endDate } = toRefs(props);
    const startModel = useVModel(props, 'start', emit);

    const endModel = useVModel(props, 'end', emit);

    const checkData = (time) => {
      let disabled = false;

      if (startDate.value && dayjs(time).isBefore(startDate.value, 'date')) {
        disabled = true;
      }

      if (endDate.value && dayjs(time).isAfter(endDate.value, 'date')) {
        disabled = true;
      }
      return disabled;
    };

    const disabledStartDate = (time) => {
      let disabled = false;

      if (checkData(time)) {
        disabled = true;
      }

      return disabled;
    };

    const disabledEndDate = (time) => {
      let disabled = false;

      if (dayjs(time).isBefore(dayjs(startModel.value))) {
        disabled = true;
      }
      if (!disabled && checkData(time)) {
        disabled = true;
      }
      return disabled;
    };
    const checkTimeStart = () => {
      if (dayjs(endModel.value).isBefore(dayjs(startModel.value))) {
        endModel.value = null;
        ElMessage.error('開始日期晚於結束日期，請重新選擇結束日期');
      }
    };
    return {
      startModel,
      endModel,
      disabledEndDate,
      checkTimeStart,
      disabledStartDate,
    };
  },
};
</script>
<style></style>
