<template>
  <div class="text-primary text-3xl text-center">{{ text }}</div>
</template>

<script>
import { inject } from 'vue';
export default {
  setup(props) {
    const { state } = inject('analyzeData');

    return { text: state };
  },
};
</script>

<style lang="scss" scoped></style>
