<template>
  <span
    v-loading="loading"
    class="underline cursor-pointer"
    @click.prevent="to"
    >{{ row.name }}</span
  >
</template>
<script>
import { toRefs, ref } from 'vue';

import Subject from '@/api/Subject';
import { useRouter } from 'vue-router';
export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { row } = toRefs(props);
    const router = useRouter();

    const loading = ref(false);
    const to = async () => {
      loading.value = true;
      console.log(row.value);
      const res = await Subject.filterId({
        type: 'sbj',
        filterScopes: [
          {
            sbjKey: row.value.sbjKey,
            sbtKey: row.value.sbtKey,
            ...row.value.condition,
          },
        ],
      });

      if (res.data?.filterId) {
        router.push({
          name: 'Exam.Question',
          params: { slug: 'sbj' },
          query: {
            filterId: res.data.filterId,
            type: 'sbj',
            exItem: 'ansCorrect',
            redirectUri: encodeURIComponent(
              `/exam/sbj/filter?filterId=${res.data.filterId}&type=sbj`,
            ),
          },
        });
      } else {
        console.log('error get filter id');
        // ElMessage('無法取得filterId');
      }
      loading.value = false;
    };
    return { to, loading };
  },
};
</script>
