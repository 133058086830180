<template>
  <div class="flex p-4 flex-wrap">
    <div class="w-1/4 md:w-1/2 md:mb-6">
      <div class="text-gray text-sm">總練習題數</div>
      <div v-if="data.qTotal" class="text-5xl text-primary font-bold mt-2">
        {{ data.qTotal }}
      </div>
      <div v-else class="mt-2">目前沒有任何數據</div>
    </div>
    <div class="w-1/4 md:w-1/2 md:mb-6">
      <div class="text-gray text-sm">總答對題數</div>
      <div v-if="data.correctTotal" class="text-5xl text-info-4 font-bold mt-2">
        {{ data.correctTotal }}
      </div>
      <div v-else class="mt-2">目前沒有任何數據</div>
    </div>
    <div class="w-1/4 md:w-1/2 md:mb-6">
      <div class="text-gray text-sm">總答錯題數</div>
      <div v-if="data.wrongTotal" class="text-5xl text-danger font-bold mt-2">
        {{ data.wrongTotal }}
      </div>
      <div v-else class="mt-2">目前沒有任何數據</div>
    </div>
    <div class="w-1/4 md:w-1/2 md:mb-6">
      <div class="text-gray text-sm">總答對比率</div>
      <div v-if="data.correctRate" class="text-5xl text-yellow font-bold mt-2">
        {{ data.correctRate }}%
      </div>
      <div v-else class="mt-2">目前沒有任何數據</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
