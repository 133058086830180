<template>
  <div>
    <Title text="題型統計"></Title>
    <div v-if="qType.length" class="bg-white rounded shadow p-4 mt-4">
      <TypeBar :data="qType" :color="['#AFD4A5']"></TypeBar>
      <p v-if="searchable" class="text-xs text-gray py-3">
        (點擊項目連結可立即加強練習)
      </p>

      <Table
        data-v-step="sub1"
        class="border p-2 rounded"
        :data="qType"
        :more-btn="false"
        :searchable="searchable"
        :default-sort="defaultSort"
        @update:order="onSort"
      ></Table>
    </div>
    <div v-else class="pl-4 mt-2">目前沒有任何數據</div>
  </div>
</template>

<script>
import Table from '../chats/Table.vue';
import TypeBar from '../chats/TypeBar.vue';
import Title from '@/components/Titles.vue';
import { inject } from 'vue';
export default {
  components: {
    TypeBar,
    Table,
    Title,
  },
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { qType, sort, orderData } = inject('analyzeData');

    const onSort = (params) => {
      sort({ listKey: 'qType', ...params });
    };
    return { qType, onSort, defaultSort: orderData.qType };
  },
};
</script>
