<template>
  <div class="py-12 text-center">
    <div class="text-5xl">目前沒有任何數據分析</div>
    <div class="mt-4 text-2xl">
      多做題目累積經驗值，個人化數據分析也會更精準喔!
    </div>
    <el-button
      type="primary"
      size="large"
      class="mt-6"
      @click="$router.push({ path: '/exam/past/list' })"
      >前往QB題庫</el-button
    >
    <img src="@/assets/imgs/analysisRole3.png" alt="" class="mx-auto mt-8" />
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
