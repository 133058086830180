import { reactive, ref, watch } from 'vue';
import Analysis from '@/api/Analysis';
import dayjs from 'dayjs';
import { useRouter, useRoute } from 'vue-router';
export default ({ params }) => {
  const route = useRoute();
  const loading = ref(false);
  const form = reactive({
    type: 'week',
  });

  const data = reactive([]);
  watch(
    () => form.type,
    (val) => {
      if (!val) return;
      fetcher();
    },
    { immediate: false },
  );

  const fetcher = () => {
    const _form = { ...params, ...form };

    if (!_form.period) return;
    loading.value = true;
    return Analysis.overallBar({ ..._form }).then((res) => {
      if (res.code === 1) {
        data.length = 0;
        data.push(...res.data.rows);
      }

      loading.value = false;
      return res;
    });
  };

  return {
    form,
    fetcher,
    data,
    loading,
  };
};
