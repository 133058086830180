<template>
  <div>
    <TitlesVue text="錯題關鍵字統計"></TitlesVue>
    <div v-if="rows?.length > 0" class="mt-4 bg-white shadow rounded p-4">
      <div v-if="searchable" class="flex justify-between pb-4">
        <div class="text-gray text-sm pl-4">(點擊關鍵字連結可立即加強練習)</div>
      </div>

      <div
        class="max-h-96 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
      >
        <div class="flex mt-4" :class="{ 'space-x-12': size === 'large' }">
          <ElTable :data="rows1">
            <el-table-column label="#" prop="index" width="50" />
            <ElTableColumn label="關鍵字">
              <template #default="scope">
                <SearchLink v-if="searchable" :row="scope.row"></SearchLink>
                <span v-else>{{ scope.row.name }}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="出現次數" prop="wrongCnt"></ElTableColumn>
          </ElTable>

          <ElTable v-if="displayTwoCol" :data="rows2">
            <el-table-column label="#" prop="index" width="50" />

            <ElTableColumn label="關鍵字">
              <template #default="scope">
                <SearchLink v-if="searchable" :row="scope.row"></SearchLink>
                <span v-else>{{ scope.row.name }}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="出現次數" prop="wrongCnt"></ElTableColumn>
          </ElTable>
        </div>
      </div>
    </div>
    <div v-else class="pl-4 mt-2">目前沒有任何數據</div>

    <!--  -->
  </div>
</template>

<script>
import TitlesVue from '@/components/Titles.vue';
import { ElTable, ElTableColumn } from 'element-plus';

import { computed, inject } from 'vue';
import SearchLink from '../_SearchLink.vue';
import _ from 'lodash';
import useToggleRows from '@/hook/useToggleRows.js';
export default {
  components: {
    TitlesVue,
    ElTable,
    ElTableColumn,
    SearchLink,
  },
  props: {
    size: {
      type: String,
      default: 'large',
      validator(value) {
        return ['normal', 'large'].includes(value);
      },
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const LIMIT = props.size === 'large' ? 200 : 100;
    const { sbjIndex } = inject('analyzeData');
    const isMobile = inject('isMobile');

    const displayTwoCol = computed(() => {
      return props.size === 'large' && !isMobile;
    });

    const initData = (data) => {
      data = _.slice(data, 0, LIMIT);
      // add index
      _.map(data, (e, i) => {
        return _.extend(e, { index: i + 1 });
      });
      return data;
    };
    const rows1 = computed(() => {
      let data = initData(_.cloneDeep(sbjIndex));

      if (displayTwoCol.value) {
        data = data.filter((element, index) => {
          return index % 2 === 0;
        });
      }
      return data;
    });
    const rows2 = computed(() => {
      if (!displayTwoCol.value) return [];

      let data = initData(_.cloneDeep(sbjIndex));
      data = data.filter((element, index) => {
        return index % 2 === 1;
      });
      return data;
    });
    return {
      // open,
      // setCls,
      rows: sbjIndex,
      // toggle,
      // halfPoint,
      displayTwoCol,
      // active,
      rows1,
      rows2,
    };
  },
};
</script>
