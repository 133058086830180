<template>
  <Tour v-if="show === true" :rows="rows" @onEnd="done(key)"></Tour>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue';
import Tour from '@/modules/tour/Index.vue';

const key = 'analysisAll';
const { done, status } = inject('tour');
//
const rows = [
  {
    target: '[data-v-step="all"]',
    header: {
      title: `全科目統計分析`,
    },
    content: `有分為全科目統計跟單一科目的統計數據。`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '[data-v-step="date"]',
    header: {
      title: `設定統計資料的時間區間`,
    },
    content: `可依照用戶需求調整時間區間來顯示統計的資料。`,
    params: {
      enableScrolling: false,
    },
  },
];

const show = ref(null);
onMounted(() => {
  show.value = status[key];
});
</script>

<style lang="sass" scoped>
:deep(.v-step)
  &::after
    background-image: url(~@/assets/imgs/menuRole4.png)
</style>
