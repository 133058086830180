<template>
  <div>
    <Title text="章節統計"></Title>

    <div v-if="data.length > 0" class="bg-white rounded shadow p-4 mt-4">
      <div class="flex justify-between">
        <p class="text-xs text-gray py-3">(點擊項目連結可立即加強練習)</p>

        <el-select v-model="type">
          <el-option key="part" value="part" label="PART"></el-option>
          <el-option key="chapter" value="chapter" label="章"></el-option>
        </el-select>
        <!-- <el-button type="info" size="default">顯示小節</el-button> -->
      </div>

      <ElTable
        ref="tableRef"
        :data="data"
        class="mt-4"
        row-key="name"
        :tree-props="{ children: 'children' }"
        :expand-row-keys="expands"
        :default-sort="orderData"
        @sort-change="onSorter"
      >
        <el-table-column label="#" width="50"></el-table-column>
        <el-table-column label="章節">
          <template #default="scope">
            <div>
              <SearchLink :row="scope.row"></SearchLink>

              <!-- <div class="text-xs text-gray">{{ scope.row.sname }}</div> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="答題數"
          prop="ansCnt"
          width="100"
          :sortable="'custom'"
        >
        </el-table-column>
        <el-table-column
          label="答對比率"
          :sortable="'custom'"
          width="100"
          prop="correctRate"
        >
        </el-table-column>
      </ElTable>
    </div>
    <div v-else class="pl-4 mt-2">目前沒有任何數據</div>
  </div>
</template>

<script>
import Title from '@/components/Titles.vue';
import { ref, inject, computed, reactive, onUpdated, watch } from 'vue';
import SearchLink from '../_SearchLink.vue';
import { ElTable, ElTableColumn, ElSelect, ElOption } from 'element-plus';
import { getScoreColor } from '@/utils';
import _ from 'lodash';
export default {
  components: {
    Title,
    ElTable,
    ElTableColumn,

    ElSelect,
    ElOption,
    SearchLink,
  },
  setup() {
    const { scope } = inject('analyzeData');
    const type = ref('part');
    const orderDataTemp = {
      order: 'descending',
      prop: 'correctRate',
    };
    const orderData = reactive(_.cloneDeep(orderDataTemp));

    const data = computed(() => {
      const _data = [];
      const _scope = _.cloneDeep(scope);

      if (type.value === 'chapter') {
        _scope.forEach((x) => {
          _data.push(...x.children);
        });
      } else {
        _data.push(..._scope);
      }

      _data.sort((a, b) => {
        return orderData.order === 'descending'
          ? b[orderData.prop] - a[orderData.prop]
          : a[orderData.prop] - b[orderData.prop];
      });

      return _data;
    });
    const expands = ref([]);
    const onSorter = ({ order, prop }) => {
      expands.value = [];
      orderData.order = order;
      orderData.prop = prop;
    };
    const tableRef = ref(null);

    return {
      data,
      type,
      onSorter,
      getScoreColor,
      expands,
      tableRef,
      orderData,
    };
  },
};
</script>

<style lang="sass" scoped>
:deep(.el-table__row--level-1)
  .el-table__cell:nth-child(2)
    @apply pl-4

:deep(.el-table__row--level-2)
  .el-table__cell:nth-child(2)
    @apply pl-8
:deep(.el-table__row--level-3)
  .el-table__cell:nth-child(2)
    @apply pl-12
</style>
