import { reactive, shallowReactive, ref, watch } from 'vue';
import Analysis from '@/api/Analysis';
import dayjs from 'dayjs';
import { useRouter, useRoute } from 'vue-router';
import _ from 'lodash';

export default ({ params, dataType }) => {
  const route = useRoute();
  const updTime = ref(null);
  const state = ref('');
  const subject = shallowReactive([]);
  const overall = shallowReactive({});

  const qType = shallowReactive([]);
  const attribute = shallowReactive([]);
  const sbjIndex = shallowReactive([]);
  const scope = shallowReactive([]);

  const loading = ref(false);
  const history = {
    subject,
    qType,
    attribute,
  };
  const orderDataTemp = {
    subject: {
      listKey: 'subject',
      prop: 'correctRate',
      order: 'descending',
    },
    attribute: {
      listKey: 'attribute',
      prop: 'correctRate',
      order: 'descending',
    },
    // 被放到scope中了  應該要移出來 才對
    // scope: {
    //   listKey: 'scope',
    //   prop: 'correctRate',
    //   order: 'descending',
    // },
    qType: {
      listKey: 'qType',
      prop: 'correctRate',
      order: 'descending',
    },
  };
  const orderData = reactive(_.cloneDeep(orderDataTemp));
  const resetOrder = () => {
    Object.assign(orderData, _.cloneDeep(orderDataTemp));
  };
  const dataTemp = { subject, qType, attribute, sbjIndex, scope };

  const fetcher = () => {
    if (!params.period) return;
    loading.value = true;

    return Analysis.overall({ ...params }).then((res) => {
      if (res.code === 1) {
        // 應該用 :key的方式強迫重新render
        resetOrder();
        const opt =
          dataType && dataType.find((x) => x.key === route.query.dataType);
        const sbtKey = opt && opt.sbtId;
        const sbjKey =
          route.query.dataType !== 'all' ? route.query.dataType : null;

        updTime.value = res.data.updTime;

        state.value = res.data.state;

        if (res.data.subject) {
          subject.splice(0, subject.length, ...res.data.subject);
          history.subject = _.cloneDeep(subject);
          sort(orderData.subject);
        }

        if (res.data.qType) {
          qType.splice(
            0,
            qType.length,
            ...res.data.qType.map((x) => {
              return {
                ...x,
                sbtKey,
                sbjKey,
                condition: { qType: [x.id] },
              };
            }),
          );
          history.qType = _.cloneDeep(qType);
          sort(orderData.qType);
        }
        if (res.data.attribute) {
          attribute.splice(
            0,
            attribute.length,
            ...res.data.attribute.map((x) => {
              return {
                ...x,
                sbtKey,
                sbjKey,
                condition: { attribute: [x.id] },
              };
            }),
          );
          history.attribute = _.cloneDeep(attribute);

          sort(orderData.attribute);
        }

        if (res.data.sbjIndex) {
          sbjIndex.splice(
            0,
            sbjIndex.length,
            ...res.data.sbjIndex.map((x) => {
              return {
                ...x,
                sbtKey,
                sbjKey,
                condition: { keyword: x.name },
              };
            }),
          );
        }
        const setProp = (row, condition) => {
          row['key'] = row.id + row.name;
          row['condition'] = condition;
          row['sbtKey'] = sbtKey;
          row['sbjKey'] = sbjKey;
        };

        if (res.data.scope) {
          res.data.scope.forEach((x) => {
            setProp(x, { part: [x.id] });

            if (x.chapter && x.chapter.length) {
              x['children'] = x.chapter;
              delete x.chapter;
              x.children.forEach((y) => {
                setProp(y, { ...x['condition'], chapter: [y.id] });

                if (y.section && y.section.length) {
                  y['children'] = y.section;

                  y.children.forEach((z) => {
                    setProp(z, { ...y['condition'], section: [z.id] });
                  });
                  delete y.section;
                }
              });
            }
          });

          scope.splice(0, scope.length, ...res.data.scope);
        }

        Object.assign(overall, { ...res.data.overall });
      } else {
        console.log('not thing');
      }

      loading.value = false;
      return res;
    });
  };
  const sort = ({ listKey, prop, order }) => {
    // 會按造listKey, prop, order  條件排序

    orderData[listKey] = { listKey, prop, order };

    const _compare = (d) => {
      let _data = _.cloneDeep(d);
      _data.sort((a, b) => {
        if (a[prop] === undefined || b[prop] === undefined) return 1;
        let result = 0;
        let _a = a[prop];
        let _b = b[prop];
        if (typeof a[prop] === 'number' && typeof b[prop] === 'number') {
          // 這段目前用步道
          result = order === 'descending' ? _b - _a : _a - _b;
        } else {
          _a = _a.toString();
          _b = _b.toString();
          result =
            order === 'descending'
              ? _b.localeCompare(_a, 'zh-Hant')
              : _a.localeCompare(_b, 'zh-Hant');
        }

        return result;
      });
      return _data;
    };

    const sortedData = !prop
      ? _.cloneDeep(history[listKey])
      : _compare(dataTemp[listKey]);

    dataTemp[listKey].splice(0, dataTemp[listKey].length, ...sortedData);
  };

  return {
    loading,
    orderData,
    updTime,
    fetcher,
    state,
    subject,
    overall,
    qType,
    attribute,
    sbjIndex,
    scope,
    sort,
  };
};
