import { reactive, ref, watch } from 'vue';

import dayjs from 'dayjs';
import { useRouter, useRoute } from 'vue-router';
export default () => {
  const route = useRoute();
  const form = reactive({
    start:
      route.query.start || dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
    end: route.query.end || dayjs().format('YYYY-MM-DD'),
    dataType: route.query.dataType || 'all',
    ...route.query,
  });
  const params = reactive({});
  watch(
    () => form,
    (val) => {
      if (!val) return;
      const _start = val.start ? val.start.replaceAll('-', '') : null;
      const _end = val.end ? val.end.replaceAll('-', '') : null;

      Object.assign(params, {
        period: _start && _end ? _start + '-' + _end : null,
        sbjId: val.dataType && val.dataType === 'all' ? null : val.dataType,
      });
    },
    { deep: true, immediate: true },
  );

  return {
    form,
    params,
  };
};
