<template>
  <div>
    <v-chart ref="chart" :option="option" class="h-[300px]" />
  </div>
</template>

<script>
import { use } from 'echarts/core';

import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from 'vue-echarts';
import { ref, computed, watchEffect } from 'vue';
import _ from 'lodash';

use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
  BarChart,
  CanvasRenderer,
]);

export default {
  components: {
    VChart,
  },
  props: {
    flip: {
      type: Boolean,
      default: false,
    },
    color: {
      type: Array,
      default: () => ['#003b8b'],
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  setup(props) {
    const option = computed(() => {
      const AxisData1 = {
        type: 'value',
        name: '答對比率',
        min: 0,
        max: 100,
      };
      const AxisData2 = {
        type: 'category',
        inverse: !props.flip,
        data: props.data.map((x) => x.name.match(/.{1,4}/g).join('\n')),
      };

      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        color: props.color,
        grid: {
          left: 60,
          top: 30,
        },
        xAxis: !props.flip ? AxisData1 : AxisData2,
        yAxis: !props.flip ? AxisData2 : AxisData1,
        series: [
          {
            name: '答對比率',
            type: 'bar',
            data: props.data.map((x) => {
              return {
                value: x.correctRate,
              };
            }),
          },
          {
            name: '答題數',
            type: 'line',
            color: 'transparent',
            data: props.data.map((x) => {
              return {
                value: x.ansCnt,
              };
            }),
          },
        ],
      };
    });
    return { option };
  },
};
</script>
