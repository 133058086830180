<template>
  <div v-loading.body="overallLoading || barLoading" class="container pt-12">
    <!-- 如果沒資料 -->
    <Intro v-if="false"></Intro>
    <!-- 有資料 -->
    <div v-else class="pb-16">
      <div>
        <state></state>
        <div class="text-sm text-gray mt-12">
          * 不計分題型不列入統計資料(不計分:送分題,填充,簡答,申論,計算,其他)
        </div>
        <hr class="my-4" />

        <div
          class="flex justify-between mt-4 items-start md:items-center md:flex-col md:space-y-4"
        >
          <Data-Type
            v-model="form.dataType"
            :opts="dataType.data"
            data-v-step="all"
            show-default-btn="全部"
          />
          <div
            class="space-x-4 flex items-center flex-none md:space-x-0 md:flex-col md:space-y-4"
          >
            <DateRange
              v-model:start="form.start"
              v-model:end="form.end"
              :clearable="false"
              :end-date="dayjs()"
              data-v-step="date"
            />
            <div class="ml-2 flex md:flex-col items-center">
              <div class="flex items-center">
                <el-button type="primary" @click="fetcher">
                  <font-awesome-icon :icon="['fa', 'redo-alt']"
                /></el-button>
                <span class="text-xs ml-2"
                  >最後測驗更新時間 : {{ updTime }}<br />
                  (數據持續更新中，請耐心等待)</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!$route.query.dataType || $route.query.dataType === 'all'"
        class="grid gap-8 grid-cols-2 mt-8 md:mt-12 md:grid-cols-1"
      >
        <div class="col-span-2 md:col-span-1">
          <Title text="科目表現分析" />
          <div
            class="bg-white rounded shadow p-4 grid gap-4 grid-cols-2 mt-4 md:grid-cols-1 md:gap-8"
          >
            <!-- 科目表現分析 -->
            <Subject></Subject>
            <!-- 整體表現 -->
            <OverallBar :data="bar.data" class="col-span-2 md:col-span-1" />

            <Overview
              :data="analyzeData.overall"
              class="col-span-2 md:col-span-1"
            />
          </div>
        </div>

        <!-- 題型統計 -->
        <Type :searchable="false" />
        <!-- 問題屬性統計 -->
        <Attributes :searchable="false" />
        <!-- 錯題關鍵字統計 -->
        <Keywords :searchable="false" class="col-span-2 md:col-span-1" />
      </div>
      <div v-else>
        <div class="grid gap-8 grid-cols-2 md:grid-cols-1 mt-8">
          <div class="col-span-2 md:col-span-1">
            <Title text="科目表現分析" />
            <div
              class="bg-white rounded shadow p-4 grid gap-4 grid-cols-2 md:grid-cols-1 mt-4"
            >
              <OverallBar :data="bar.data" class="col-span-2 md:col-span-1" />

              <Overview
                :data="analyzeData.overall"
                class="col-span-2 md:col-span-1"
              />
            </div>
          </div>
          <!-- 題型統計 -->
          <Type :searchable="true" />
          <!-- 錯題關鍵字統計 -->
          <Keywords :searchable="true" size="normal" />
          <!-- 問題屬性統計 -->
          <Attributes :searchable="true" />
          <Scope :key="$route.query.dataType" />
        </div>
      </div>
    </div>

    <TourAll v-if="tourStatus === 'all'"></TourAll>
  </div>
</template>

<script>
import { provide, watch, onMounted, ref } from 'vue';
import Intro from '@/modules/analyze/Intro.vue';
// import Header from '@/modules/analyze/Header.vue';
import useAnalyze from '@/modules/analyze/useAnalyze.js';
import useForm from '@/modules/analyze/useForm.js';
import useBar from '@/modules/analyze/useBar.js';
import useDataType from '@/modules/analyze/useDataType.js';

import Title from '@/components/Titles.vue';
// import Table from '@/modules/analyze/chats/Table.vue';
import Subject from '@/modules/analyze/widget/Subject.vue';
// import RateBar from '@/modules/analyze/chats/RateBar.vue';
import Overview from '@/modules/analyze/widget/Overview.vue';
import OverallBar from '@/modules/analyze/chats/OverallBar.vue';
import Scope from '@/modules/analyze/widget/Scope.vue';
import Attributes from '@/modules/analyze/widget/Attributes.vue';
import Type from '@/modules/analyze/widget/Type.vue';
import Keywords from '@/modules/analyze/widget/Keywords.vue';
import State from '@/modules/analyze/State.vue';
import DataType from '@/components/filter/DataType.vue';

import _ from 'lodash';
import { useRouter, useRoute } from 'vue-router';
import { isEmpty } from '@/utils/index';
import dayjs from 'dayjs';
import DateRange from '@/components/form/DateRange.vue';

import TourAll from './_TourAll.vue';

export default {
  components: {
    Intro,
    // Header,
    State,
    DataType,

    Attributes,
    Type,
    Keywords,
    // Subject,
    Scope,
    Subject,
    Title,

    OverallBar,
    Overview,
    DateRange,
    TourAll,
  },
  setup() {
    const { form, params } = useForm();
    provide('formData', { form, params });
    const dataType = useDataType({
      params,
    });

    const analyzeData = useAnalyze({
      params,
      dataType: dataType.data,
    });

    provide('analyzeData', analyzeData);

    const bar = useBar({
      params,
    });
    provide('overallBarData', bar);

    const router = useRouter();
    const route = useRoute();
    const syncQuery = () => {
      router.push({
        query: { ..._.omitBy({ ...route.query, ...form }, isEmpty) },
      });
    };

    const fetcher = async () => {
      await dataType.fetcher();
      await analyzeData.fetcher();
      await bar.fetcher();
      return Promise.resolve();

      // return Promise.all([
      //   dataType.fetcher(),
      //   analyzeData.fetcher(),
      //   bar.fetcher(),
      // ]).then(() => {
      //   Promise.resolve();
      // });
    };

    const tourStatus = ref(null);
    const updateTourStatus = (type) => {
      // api 判斷
      if (type) {
        tourStatus.value = type === 'all' ? 'all' : 'sub';
      } else {
        tourStatus.value = null;
      }
    };

    watch(
      () => _.cloneDeep(form),
      (val, old) => {
        if (_.isEqual(val, old)) return;
        syncQuery();
        setTimeout(() => {
          fetcher();
        }, 0);

        //tour切換/
        if (val.dataType !== old.dataType) {
          updateTourStatus(val.dataType);
        }
      },
      { immediate: false, deep: true },
    );

    const year2 = 1000 * 60 * 60 * 24 * 365 * 0.5;
    const disableDate = (date) => {
      return Math.abs(dayjs() - date) > year2;
    };

    onMounted(async () => {
      await dataType.fetcher();
      await analyzeData.fetcher();
      await bar.fetcher();
      //tour起始/
      updateTourStatus(form.dataType);
    });

    return {
      fetcher,
      disableDate,
      bar,
      form,
      dataType,
      analyzeData,
      // loading,
      overallLoading: analyzeData.loading,
      barLoading: bar.loading,
      updTime: analyzeData.updTime,
      dayjs,

      tourStatus,
    };
  },
};
</script>
