<template>
  <div>
    <template v-if="rows && rows.length">
      <ElTable
        ref="tableRef"
        :data="rows"
        :default-sort="defaultSort"
        :height="tableHeight"
        @sort-change="onSort"
      >
        <el-table-column sortable="custom" prop="name" :label="title">
          <template #default="scope">
            <SearchLink v-if="searchable" :row="scope.row"></SearchLink>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="答題數"
          prop="ansCnt"
          width="100"
          sortable="custom"
        >
        </el-table-column>
        <!--  -->
        <el-table-column
          label="答對比率"
          prop="correctRate"
          sortable="custom"
          width="100"
        >
          <template #default="scope">
            <ElPopover
              placement="top"
              trigger="hover"
              :hide-after="0"
              width="200"
            >
              <template #reference>
                <div
                  v-if="!isNaN(scope.row.correctRate)"
                  :style="{
                    backgroundColor: getScoreColor(
                      parseFloat(scope.row.correctRate),
                    ),
                  }"
                  class="py-1 px-2 w-[70px] text-center rounded text-white inline-block whitespace-nowrap"
                >
                  {{ parseFloat(scope.row.correctRate) }}%
                </div>
                <div v-else class="text-center w-12">-</div>
              </template>
              <div class="">
                對題
                <span class="text-info-1 text-xl mr-2">
                  {{ scope.row.correctCnt }}
                </span>
                錯題
                <span class="text-danger text-xl">
                  {{ scope.row.ansCnt - scope.row.correctCnt }}
                </span>
              </div>
            </ElPopover>
          </template>
        </el-table-column>
        <el-table-column v-if="moreBtn" width="115">
          <template #default="scope">
            <el-button size="default" @click="watchAnalyze(scope.row.id)"
              >查看分析</el-button
            >
          </template>
        </el-table-column>
      </ElTable>
    </template>
    <div v-else class="pl-4 mt-2">目前沒有任何數據</div>
  </div>
</template>

<script>
import { ref, toRefs, onUpdated } from 'vue';
import { ElTable, ElTableColumn, ElPopover } from 'element-plus';
import { getScoreColor } from '@/utils';

import SearchLink from '../_SearchLink.vue';

export default {
  components: {
    ElTable,
    ElTableColumn,
    ElPopover,
    SearchLink,
  },
  props: {
    tableHeight: {
      type: Number,
      default: 300,
    },
    title: {
      type: String,
      default: '項目',
    },
    defaultSort: {
      type: Object,
      default: () => {
        return {
          prop: 'correctRate',
          order: 'descending',
        };
      },
    },
    data: {
      type: Array,
      default: () => [],
    },
    moreBtn: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:order', 'update:datatype'],
  setup(props, { emit }) {
    const limit = ref(5);
    const onSort = (params) => {
      emit('update:order', params);
    };

    const watchAnalyze = (id) => {
      emit('update:datatype', id);
    };

    const tableRef = ref(null);
    onUpdated(() => {
      if (tableRef.value) {
        tableRef.value.sort(props.defaultSort.prop, props.defaultSort.order);
      }
    });

    return {
      tableRef,
      onSort,
      watchAnalyze,
      getScoreColor,
      rows: props.data,
      limit,
    };
  },
};
</script>
