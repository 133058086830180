<template>
  <Table
    v-if="subject.length"
    class="border p-2 rounded"
    title="科目"
    :data="subject"
    :default-sort="defaultSort"
    :table-height="500"
    @update:order="onSort"
    @update:datatype="onDatatype"
  />
  <!-- 各科答對率 -->
  <RateBar v-if="subject.length" :data="subject" />
</template>

<script>
import { inject } from 'vue';

import Table from '../chats/Table.vue';
import RateBar from '../chats/RateBar.vue';

export default {
  components: {
    RateBar,

    Table,
  },

  setup() {
    const { subject, sort, orderData } = inject('analyzeData');
    const { form } = inject('formData');
    const onSort = (params) => {
      sort({ listKey: 'subject', ...params });
    };

    const onDatatype = (val) => {
      form.dataType = val;
    };

    return { subject, onSort, onDatatype, defaultSort: orderData.subject };
  },
};
</script>
