<template>
  <div>
    <p class="text-sm text-gray pl-4">各科答對率</p>
    <v-chart v-if="data.length > 0" :option="option" class="h-[500px]" />
    <div v-else class="pl-4 mt-2">目前沒有任何數據</div>
  </div>
</template>

<script>
import { use } from 'echarts/core';

import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from 'vue-echarts';
import { ref, computed } from 'vue';

use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
  BarChart,
  CanvasRenderer,
]);

export default {
  components: {
    VChart,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const targetRates = computed(() => {
      return props.data.map((x) => x.targetAvgRate); //[80,80,80]
    });

    const correctRates = computed(() => {
      //[60,80,82]

      return props.data.map((x) => {
        return {
          value: x.correctRate,
          itemStyle: {
            color: x.correctRate < x.targetRate ? '#f36e65' : '#78c2a7',
          },
        };
      });
    });

    const titles = computed(() => {
      return props.data.map((x) => x.name);
    });

    const option = computed(() => {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        color: ['#003b8b'], // var(-color-promary)
        grid: {
          left: 120,
          top: 30,
        },
        xAxis: {
          type: 'value',
          name: '答對比率',
          min: 0,
          max: 100,
        },

        yAxis: {
          type: 'category',
          inverse: true,
          data: titles.value,
          axisLabel: {
            formatter: function (value) {
              return value.match(/.{1,8}/g).join('\n');
            },
            align: 'right',
            margin: 10,
          },
        },
        series: [
          {
            name: '答對比率',
            type: 'bar',
            data: correctRates.value,
            // label: {
            //   show: true,
            //   formatter: '目前: {@score}%',
            // },
          },
          // {
          //   name: '目標正確率',
          //   type: 'bar',
          //   // label: {
          //   //   show: true,
          //   //   formatter: '目標: {@score}%',
          //   // },
          //   data: targetRates.value,
          // },
        ],
      };
    });

    return { option };
  },
};
</script>
