<template>
  <div>
    <div class="flex mb-4">
      <p class="text-sm text-gray pl-4">整體表現</p>
      <El-Select v-model="form.type" class="ml-auto w-[6rem]">
        <el-option label="月" value="month">月</el-option>
        <el-option label="周" value="week">周</el-option>
        <el-option label="天" value="day">天</el-option>
      </El-Select>
    </div>

    <v-chart v-if="data.length > 0" :option="option" class="h-[600px]" />
    <div v-else class="pl-4 mt-2">目前沒有任何數據</div>
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { ElSelect, ElOption } from 'element-plus';

import {
  DataZoomComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from 'vue-echarts';
import { inject, watch, toRefs, reactive, computed, ref } from 'vue';
import { getMonthName } from '@/utils';

use([
  DataZoomComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
]);

export default {
  components: {
    VChart,
    ElSelect,
    ElOption,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const { form } = inject('overallBarData');
    const { data } = toRefs(props);

    const totals = ref([]);
    const avgCorrectRates = ref([]);
    const xAxis = ref([]);

    watch(
      data,
      (val) => {
        if (!val) return;
        if (val.length) {
          totals.value = [];
          avgCorrectRates.value = [];
          xAxis.value = [];

          val.forEach((x, i) => {
            totals.value[i] = x.ansCnt;
            avgCorrectRates.value[i] = x.avgCorrectRate;
            let _date = x.date.split('-');
            _date[1] = getMonthName(_date[1]).toUpperCase();
            xAxis.value[i] = _date.join('\n');
          });
        }
      },
      { deep: true, immediate: true },
    );

    // const titles = computed(() => {
    //   return props.data.map((x) => x.name);
    // });

    const option = computed(() => {
      return {
        grid: {
          top: 60,
          bottom: 120,
          left: '10%',
          right: '10%',
        },
        dataZoom: [
          {
            type: 'slider',
            bottom: '30',
            start: 0,
            end: 100,
            handleSize: 30,
            backgroundColor: 'rgba(255,255,255,0.4)',
            fillerColor: 'rgba(255,255,255,0.4)',
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' },
        },
        legend: {},
        xAxis: [
          {
            type: 'category',
            data: xAxis.value,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '總題數',
            min: 0,
            position: 'right',
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            name: '答對比率',
            min: 0,
            max: 100,
            position: 'left',
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        series: [
          {
            name: '總題數',
            type: 'bar',
            yAxisIndex: 0,
            data: totals.value,
          },
          {
            name: '答對比率',
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            data: avgCorrectRates.value,
          },
        ],
        color: [
          //之後應該要改成function
          '#7abecf',
          '#f36e65',
        ],
      };
    });

    return { option, form };
  },
};
</script>

<style lang="scss" scoped>
.scrollbar-flex-content {
  display: flex;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-danger-light-9);
  color: var(--el-color-danger);
}
</style>
